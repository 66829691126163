import Right from "../Right";

function Preview() {
    return (
        <div className="container-fluid d-md-inline-flex p-0 h-100">
            <Right />
        </div>
    );
}

export default Preview;