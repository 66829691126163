import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min'
import Home from "./components/Home";
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
        <Home />
    </BrowserRouter>

  );
}

export default App;
