import { Route, Routes } from 'react-router-dom';
import Navbar from './layout/Navbar';
import Preview from './layout/Preview';
import Handmade from './pages/Handmade';
import { useLocation } from 'react-router-dom';

function Home() {
    let location = useLocation().pathname;
    let homeBg = 'dark';

    if (location == '/' || location == "/software") {
        homeBg = 'dark';
    } else {
        homeBg = 'light';
    }

    return(
        <>
            <Navbar />
            <div className={`content container-fluid p-0 bg-${homeBg}`}>
                <Routes>
                    <Route path="/" element={<Preview/>}/>
                    <Route path="/handmade" element={<Handmade />}/>
                </Routes>
            </div>
        </>
    )
}

export default Home;