import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import whiteLogo from '../../assets/logowhite.png';
import blackLogo from '../../assets/logo.png';

function Navbar() {
    let location = useLocation().pathname;
    let Logo = whiteLogo;
    let navBg = 'dark';
    let textCol = 'light'
    if (location == '/' || location == '/software') {
        Logo = whiteLogo;
        navBg = 'dark';
        textCol = 'light';
    } else if (location == '/handmade') {
       Logo = blackLogo;
       navBg = 'light';
       textCol = 'dark';
    }
    return (
        <nav className={`container-fluid px-2 navbar navbar-expand-lg navbar-${navBg} text-center justify-content-center`}>
            <Link className="navbar-brand" to="/"><img className="logo" src={Logo} /> Earth Hardy Handmade</Link>
        </nav>
    )
}

export default Navbar;