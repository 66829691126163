const MAIN_ENDPOINT = 'https://graph.instagram.com/me/media?fields=permalink,id,caption,media_url,thumbnail_url&access_token=IGQWRQNUZAmRGtzUGV5aVBlU0plenhSWDhWQ1IyaVlkWFJ6bVBZAd05UUWlUS21RU3BJZAWpPMFB2bHY1UU5KMnNvUmlXNjNFaGFlV1ZAjU240S0hKS3FFWVhfXzV0N2lfY0FwbndIeXAycUdzWFJKNjJDc2dHc196b0EZD';

// IGQWRQNUZAmRGtzUGV5aVBlU0plenhSWDhWQ1IyaVlkWFJ6bVBZAd05UUWlUS21RU3BJZAWpPMFB2bHY1UU5KMnNvUmlXNjNFaGFlV1ZAjU240S0hKS3FFWVhfXzV0N2lfY0FwbndIeXAycUdzWFJKNjJDc2dHc196b0EZD

class API_STUFF {
    async get() {
        try {
            let response = await fetch(MAIN_ENDPOINT);
            let data = response.json();
            return data;
        } catch (e) {
            console.error(e);
        }
    }
}

let API = new API_STUFF();
export default API;