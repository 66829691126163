import API from '../../api/API.js';
import { useState, useEffect, Suspense } from 'react';
import etsy from '../../assets/etsy.png';
import square from '../../assets/square.png';
import instagramLogo from '../../assets/instagramlogo.png';
import instagramLogoColor from '../../assets/instagramlogocolor.png';

function Handmade() {
    let [ data, setData ] = useState([]);
    const fetchData = async () => {
        let newData = await API.get();
        if (newData) {
            let randomized = shuffle(newData.data);
            setData(...data, randomized);
        }
    }

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        while (currentIndex > 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    function overlayGen(event) {
        let elements = document.querySelectorAll(".overlay");
        for (let el of elements) {
            if (window.innerWidth < 768) {
                el.parentNode.addEventListener("touchstart", function() {
                    this.firstChild.classList.replace("d-none", "d-flex");
                    setTimeout(() => {
                        this.firstChild.firstChild.classList.remove("disabled");
                    }, 500);
                    setTimeout(() => {
                        this.firstChild.firstChild.classList.add("disabled");
                        this.firstChild.classList.replace("d-flex", "d-none");
                    }, 3000);
                });
                el.parentNode.addEventListener("touchmove", function() {
                    this.firstChild.classList.replace("d-flex", "d-none");
                    this.firstChild.firstChild.classList.add("disabled");
                })
            } else {
                el.parentNode.addEventListener("mouseover", function() {
                    this.firstChild.classList.replace("d-none", "d-flex");
                });
                el.parentNode.addEventListener("mouseout", function() {
                    this.firstChild.classList.replace("d-flex", "d-none");
                })
            }
        }
    }

    function videoTrigger(event) {
        let element = event.currentTarget.querySelector("video");
        element.addEventListener("mouseover", function () {
            element.play();
            element.loop = true;
        })
    }

    useEffect(() => {
        try {
            fetchData();       
        } catch (e) {
            console.log(e);
        }
    }, []);

    const age = () => {
        const today = new Date();
        const birthday = new Date("May 19, 1996");
        let myAge = Math.floor(((today - birthday) / 3.154e+10));
        return myAge;
    }

    return (
        <div id="handmade">
            <div className='container-fluid text-center d-flex-inline justify-content-center align-middle py-2'>
                <h2 className='handmadeTitle'>Blacksmith, bladesmith, woodworker, and more</h2>
                <h3 className='handmadeTitle'>Handmade Works/Wares</h3>
            </div>
            <div className='abouted p-0 container-fluid d-flex-inline align-middle justify-content-center'>
                <div className='container-fluid imgBack'></div>
                <div className='py-1'>
                    <h2 className="col-9 col-lg-5 py-2 mx-auto">about:</h2>
                    <p className='aboutedHand col-9 col-lg-5 mx-auto justify-content-end d-flex text-end'>
                        I am a {age()}-year old DIY fanatic. Anything that involves producing a product from raw materials fascinates me. Presently my work includes blacksmithing, woodworking, 3D modeling (Fusion 360), and other forms of self-driven work. At this time, I would say I am not taking custom work, but I can be talked into almost anything. My biggest passion is cutlery, and I am growing that skill as quickly as I can. 
                    </p>
                    <p className='col-9 col-lg-5 mx-auto text-center'>
                        For inquiries contact me at <a className="mailLink" href="mailto:earthhardyhandmade@gmail.com">earthhardyhandmade@gmail.com</a> or on Instagram
                    </p>
                </div>
            </div>
            <div className='container-fluid'>
                <h1 className='text-center py-1'><strong>Shop</strong></h1>
            </div>
            <div className='container col-6 my-auto d-flex flex-wrap p-2 justify-content-around'>
                <a href="https://earth-hardy.square.site"><img src={square} className='squareLogo shop'></img></a>
                <a href="https://www.etsy.com/shop/EarthHardyHandmade"><img src={etsy} className='etsyLogo shop'></img></a>
            </div>
            <div className='gal container-fluid d-flex-inline justify-content-center text-center p-1 bg-light'>
                <h1 className='text-center'><strong>Gallery</strong></h1>
                <a href="https://www.instagram.com/earth_hardy.js"><img className='instagramLink' src={instagramLogoColor}></img></a>
            </div>
            <div className='container-fluid bg-light p-0 d-flex justify-content-center flex-wrap' id='imagesContainer'>
                <Suspense fallback="<p>No content found</p>">
                    {
                    data.map((image, index) => {
                        if (image.media_url.search("mp4") != -1) {
                            return (
                                <div key={`${index}div`} className='card post m-2 col-8 col-md-3'>
                                    <div className='card-img-top' onLoad={videoTrigger}>
                                        <div onLoad={overlayGen} className='d-none overlay justify-content-center text-center'>
                                            <a className='disabled' href={image.permalink}><img className="instaGram" src={instagramLogo}></img></a>
                                        </div>
                                        <video className='instaVideo' poster={image.thumbnail_url} muted>
                                            <source src={image.media_url} type="video/mp4" key={`${index}image`} alt={`${index}image`}/>
                                        </video>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div key={`${index}div`} className='card post m-2 col-8 col-md-3'>
                                        <div onLoad={overlayGen} className='d-none overlay justify-content-center text-center' disabled>
                                            <a className='disabled' href={image.permalink}><img className="instaGram" src={instagramLogo}></img></a>
                                        </div>
                                    <img src={image.media_url} className="card-img-top" key={`${index}image`} alt={`${index}image`}></img>
                                </div>
                            )
                        }                    
                    })}
                </Suspense>
            </div>
            <div className="container-fluid bg-black text-white text-end"><i>this site was lovingly built with React</i></div> 
        </div>
    )

}

export default Handmade;