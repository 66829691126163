import Img from '../../src/assets/right.jpg';
import { Link } from 'react-router-dom';

function Right() {
    return (
        <div className="col-12 bg-light right d-flex justify-content-center align-items-center" style={{backgroundImage: `url(${Img})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
            <Link className='nav-link enter d-flex justify-content-center align-items-center btn darkened-right text-white' to="/handmade"><h5>Enter</h5></Link>
        </div>
    );
}

export default Right;